import '../styles/App.scss';
import { NavLink, Link } from 'react-router-dom';
function Nav() {
  const imgLaods = require('../assets/laods.jpg').default;
  
  return (
    <div className="container-nav">
      <Link to="/" style={{ textDecoration: 'none' }}>
        <div className="head-nav">
          <img src={imgLaods} alt="laods" className="img-laods" />
          <p className="title">La Ode Muhammad Nur Abdulrahman</p>
          <p className="desc">Software Engineer</p>
        </div>
      </Link>
      <div className="nav-links">
        <NavLink exact to="/" activeClassName="active">Portfolio</NavLink>
        <NavLink exact to="/contact" activeClassName="active">Contact Me</NavLink>
      </div>
    </div>
  );
}

export default Nav;
