import Contact from './components/Contact';
import Porto from './components/Porto';
import Nav from './components/Nav';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Switch>
          <Route path="/" exact component={Porto} />
          <Route path="/contact" exact component={Contact} />
          <Route component={Porto} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
