import '../styles/App.scss';
// import { useState, useEffect } from 'react';
function Porto() {
  const momotor = require('../assets/momotor.png').default;
  const momotormobile = require('../assets/momotor-mobile.png').default;
  const sarimande = require('../assets/sarimande.png').default;
  const sarimandemobile = require('../assets/sarimande-mobile.png').default;
  const kebun = require('../assets/kebun.png').default;
  const kebunmobile = require('../assets/kebun-mobile.png').default;
  const shabusiy = require('../assets/shabusiy.png').default;
  const shabusiymobile = require('../assets/shabusiy-mobile.png').default;
  const jenius = require('../assets/jenius.png').default;

  // const [dataz, setData] = useState('');
  // useEffect(() => {
  //   fetchItems();
  // }, []);

  // const fetchItems = async () => {
  //   const respon = await fetch(`http://192.168.1.101:8080`, {
  //     method: 'GET', // or 'PUT'
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //   }).then(response => response.json());
  //   setData(respon.text);
  // };
  return (
    <div className="container-content">
      <div className="head">
        {/* <p className="title">{dataz}</p> */}
        <p className="title">Portfolio</p>
        <p className="desc">See my work below.</p>
      </div>
      <div className="projects">
        <div className="item">
          <div className="images-mobile">
            <a href="https://jenius.com/" target="_blank" rel="noreferrer">
              <img src={jenius} alt="jenius.com"/>
            </a>
          </div>
          <div className="info">
            <a href="https://jenius.com/" target="_blank" rel="noreferrer">
              <p className="name">jenius</p>
            </a>
            <p className="description">
            Jenius is a revolutionary banking app with Visa debit cards to help with any of your banking activities such as savings, 
            making transactions or managing money in a simple, smart and safe way.
            </p>
          </div>
          <div className="images">
            <a href="https://jenius.com/" target="_blank" rel="noreferrer">
              <img src={jenius} alt="jenius.com"/>
            </a>
          </div>
        </div>
        <div className="item">
          <div className="images-mobile">
            <a href="https://shabusiy.com/" target="_blank" rel="noreferrer">
              <img src={shabusiymobile} alt="shabusiy.com"/>
            </a>
          </div>
          <div className="info">
            <a href="https://shabusiy.com/" target="_blank" rel="noreferrer">
              <p className="name">shabusiy.com</p>
            </a>
            <p className="description">
            Shabu Siy is an Authentic Japanese Complex that consists of all you can shabu-shabu and grill, 
            japanese traditional market with variety of yakitori, 
            and coffee shop to relax and enjoy your last time before heading off.
            </p>
          </div>
          <div className="images">
            <a href="https://shabusiy.com/" target="_blank" rel="noreferrer">
              <img src={shabusiy} alt="shabusiy.com"/>
            </a>
          </div>
        </div>
        <div className="item">
          <div className="images-mobile">
            <a href="https://momotor.id/" target="_blank" rel="noreferrer">
              <img src={momotormobile} alt="momotor.id"/>
            </a>
          </div>
          <div className="info">
            <a href="https://momotor.id/" target="_blank" rel="noreferrer">
              <p className="name">momotor.id</p>
            </a>
            <p className="description">
              momotor.id is a marketplace platform from Adira Finance that displays a variety of used motorcycles from various brands. 
              In addition to offering the convenience of direct transactions between sellers and buyers, users can also take advantage 
              of financing options from Adira Finance to purchase used and new motorcycles at momotor.id.
            </p>
          </div>
          <div className="images">
            <a href="https://momotor.id/" target="_blank" rel="noreferrer">
              <img src={momotor} alt="momotor.id"/>
            </a>
          </div>
        </div>
        <div className="item">
          <div className="images-mobile">
            <a href="https://sarimandemetropolitan.com/" target="_blank" rel="noreferrer">
              <img src={sarimandemobile} alt="sarimande"/>
            </a>
          </div>
          <div className="info">
            <a href="https://sarimandemetropolitan.com/" target="_blank" rel="noreferrer">
              <p className="name">Sarimande Metropolitan</p>
            </a>
            <p className="description">
            Sarimande Metropolitan is a restaurant that serves Minang-Nusantara dishes that has been operating for more than 20 years, 
            established in 1994, Sarimande Metropolitan has now developed into a competitive restaurant in the food business industry 
            and has collaborated with many parties to serve quality dishes.
            </p>
          </div>
          <div className="images">
            <a href="https://sarimandemetropolitan.com/" target="_blank" rel="noreferrer">
              <img src={sarimande} alt="sarimande"/>
            </a>
          </div>
        </div>
        <div className="item">
          <div className="images-mobile">
            <a href="https://kebunkumara.id/" target="_blank" rel="noreferrer">
              <img src={kebunmobile} alt="kebunkumara"/>
            </a>
          </div>
          <div className="info">
            <a href="https://kebunkumara.id/" target="_blank" rel="noreferrer">
              <p className="name">Kebun Kumara</p>
            </a>
            <p className="description">
            We are a group of city kids thirsty for knowledge more holistic to help us lead better, meaningful lives. 
            In 2016 we became familiar with permaculture and started learning to grow our own food.
            With that, our journey with Kebun Kumara was born and we have dedicated our business to supporting others 
            achieve their own version of balanced living by growing food and nurturing their garden.
            </p>
          </div>
          <div className="images">
            <a href="https://kebunkumara.id/" target="_blank" rel="noreferrer">
              <img src={kebun} alt="kebunkumara"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Porto;
